$startCircleSize: 1077px;
$endCircleSize: 272px;
$marginLeft: 54px;
$contentSize: 1020px;

@mixin ladyBackgroundStyles {
  background-position: calc(50% + (#{$contentSize} + #{$endCircleSize}) / 2 + #{$marginLeft}) calc(100% - 80px), calc(50% + (#{$contentSize} + #{$endCircleSize}) / 2 + #{$marginLeft}) calc(100% + (#{$startCircleSize} - #{$endCircleSize}) / 2 - 80px);
  background-repeat: no-repeat, no-repeat;
  background-size: 272px, 1077px;
}

.lady1BlueCircleWithOuterCircle {
  @include ladyBackgroundStyles;
  background-image: url(~assets/images/lady-1.png), url(~assets/images/blue-circle.svg);
}

.lady1RedCircleWithOuterCircle {
  @include ladyBackgroundStyles;
  background-image: url(~assets/images/lady-1.png), url(~assets/images/red-circle.svg);
}

.lady7BlueCircleWithOuterCircle {
  @include ladyBackgroundStyles;
  background-image: url(~assets/images/lady-7.png), url(~assets/images/blue-circle.svg);
  background-size: 389px 272px, 1077px;
}

.lady7RedCircleWithOuterCircle {
  @include ladyBackgroundStyles;
  background-image: url(~assets/images/lady-7.png), url(~assets/images/red-circle.svg);
  background-size: 389px 272px, 1077px;
}

.lady4RedCircleWithOuterCircle {
  @include ladyBackgroundStyles;
  background-image: url(~assets/images/lady-4.png), url(~assets/images/green-circle.svg);
}

.lady4GreenCircleWithOuterCircle {
  @include ladyBackgroundStyles;
  background-image: url(~assets/images/lady-4-green.png), url(~assets/images/green-circle.svg);
}

.lady6GreenCircleWithOuterCircle {
  @include ladyBackgroundStyles;
  background-image: url(~assets/images/lady6Green.svg), url(~assets/images/green-circle.svg);
}

.lady6RedCircleWithOuterCircle {
  @include ladyBackgroundStyles;
  background-image: url(~assets/images/lady-6.png), url(~assets/images/red-circle.svg);
}

.lady2RedCircleWithOuterCircle {
  @include ladyBackgroundStyles;
  background-image: url(~assets/images/lady-2.png), url(~assets/images/red-circle.svg);
  background-size: 389px 272px, 1077px;
}

.lady4GreenCircle {
  background-image: url(~assets/images/lady-4-green.png);
  background-position: calc(50% + (#{$contentSize} + #{$endCircleSize}) / 2 + #{$marginLeft}) calc(100% - 80px);
  background-repeat: no-repeat;
  background-size: 272px;
}

