@import "~assets/fonts/fonts.css";

/*disable default error boundary */
.disable-default-error > iframe {
  display: none !important;
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: "Lota Grotesque Alt 3", sans-serif;
  -moz-osx-font-smoothing: grayscale;
}

body {
  overflow: hidden;

  --spacing-none: 0;
  --spacing-xs: 8px;
  --spacing-s: 12px;
  --spacing-m: 16px;
  --spacing-l: 24px;

  @media (min-width: 600px) and (max-width: 959px) {
    --spacing-xs: 8px;
    --spacing-s: 14px;
    --spacing-m: 24px;
    --spacing-l: 32px;
  }

  /* Styles for desktop devices */
  @media (min-width: 960px) {
    --spacing-xs: 8px;
    --spacing-s: 16px;
    --spacing-m: 24px;
    --spacing-l: 36px;
  }
}

* {
  box-sizing: border-box;

  &,
  &:before,
  &:after {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
  }
}

button,
input,
a {
  outline: none !important;
}

#root {
  width: 100%;
  overflow: auto;
  background: #fcfdfd;
  height: 100%;
}

body.disableScrolling,
#root.disableScrolling {
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-weight: 400;
  margin: 0;
}

ol,
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

strong {
  font-weight: 600;
}

button,
input,
textarea,
select {
  font-family: "Lota Grotesque Alt 3", sans-serif;
  font-size: inherit;
}

.FlashAnimation {
  -webkit-animation: flash linear 1.5s;
  animation: flash linear 1.5s;
}

@-webkit-keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

.app-content-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  overflow: auto;
  height: 100%;
  background-color: #fcfdfd;
}

.MuiFormControl-root {
  position: relative !important;
}

.MuiFormControl-marginNormal {
  margin-top: var(--spacing-xs) !important;
  margin-bottom: var(--spacing-xs) !important;
}

.MuiPickersCalendarHeader-transitionContainer {
  overflow: visible !important;
}

.MuiPickersModal-dialogRoot {
  button {
    text-decoration: none;
  }
}
