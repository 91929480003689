/*Lota Grotesque Alt 3*/
@font-face {
    font-family: "Lota Grotesque Alt 3";
    src: url("Los Andes - Lota Grotesque Alt 3 Black.otf") format("otf"),
    url("Los Andes - Lota Grotesque Alt 3 Black.otf") format("truetype");
    font-style: normal;
    font-weight: 700; }
@font-face {
    font-family: "Lota Grotesque Alt 3";
    src: url("Los Andes - Lota Grotesque Alt 3 Black It.otf") format("otf"),
    url("Los Andes - Lota Grotesque Alt 3 Black It.otf") format("truetype");
    font-style: italic;
    font-weight: 700; }
@font-face {
    font-family: "Lota Grotesque Alt 3";
    src: url("Los Andes - Lota Grotesque Alt 3 Bold.otf") format("otf"),
    url("Los Andes - Lota Grotesque Alt 3 Bold.otf") format("truetype");
    font-style: normal;
    font-weight: 600; }
@font-face {
    font-family: "Lota Grotesque Alt 3";
    src: url("Los Andes - Lota Grotesque Alt 3 Bold It.otf") format("otf"),
    url("Los Andes - Lota Grotesque Alt 3 Bold It.otf") format("truetype");
    font-style: italic;
    font-weight: 600; }
@font-face {
    font-family: "Lota Grotesque Alt 3";
    src: url("Los Andes - Lota Grotesque Alt 3 Semi Bold.otf") format("otf"),
    url("Los Andes - Lota Grotesque Alt 3 Semi Bold.otf") format("truetype");
    font-style: normal;
    font-weight: 500; }
@font-face {
    font-family: "Lota Grotesque Alt 3";
    src: url("Los Andes - Lota Grotesque Alt 3 Semi Bold It.otf") format("otf"),
    url("Los Andes - Lota Grotesque Alt 3 Semi Bold It.otf") format("truetype");
    font-style: italic;
    font-weight: 500; }
@font-face {
    font-family: "Lota Grotesque Alt 3";
    src: url("Los Andes - Lota Grotesque Alt 3 Regular.otf") format("otf"),
    url("Los Andes - Lota Grotesque Alt 3 Regular.otf") format("truetype");
    font-style: normal;
    font-weight: 400; }
@font-face {
    font-family: "Lota Grotesque Alt 3";
    src: url("Los Andes - Lota Grotesque Alt 3 Regular It.otf") format("otf"),
    url("Los Andes - Lota Grotesque Alt 3 Regular It.otf") format("truetype");
    font-style: italic;
    font-weight: 400; }
@font-face {
    font-family: "Lota Grotesque Alt 3";
    src: url("Los Andes - Lota Grotesque Alt 3 Light.otf") format("otf"),
    url("Los Andes - Lota Grotesque Alt 3 Light.otf") format("truetype");
    font-style: normal;
    font-weight: 300; }
@font-face {
    font-family: "Lota Grotesque Alt 3";
    src: url("Los Andes - Lota Grotesque Alt 3 Light It.otf") format("otf"),
    url("Los Andes - Lota Grotesque Alt 3 Light It.otf") format("truetype");
    font-style: italic;
    font-weight: 300; }
@font-face {
    font-family: "Lota Grotesque Alt 3";
    src: url("Los Andes - Lota Grotesque Alt 3 Extra Light.otf") format("otf"),
    url("Los Andes - Lota Grotesque Alt 3 Extra Light.otf") format("truetype");
    font-style: normal;
    font-weight: 200; }
@font-face {
    font-family: "Lota Grotesque Alt 3";
    src: url("Los Andes - Lota Grotesque Alt 3 Extra Light It.otf") format("otf"),
    url("Los Andes - Lota Grotesque Alt 3 Extra Light It.otf") format("truetype");
    font-style: italic;
    font-weight: 200; }
@font-face {
    font-family: "Lota Grotesque Alt 3";
    src: url("Los Andes - Lota Grotesque Alt 3 Thin.otf") format("otf"),
    url("Los Andes - Lota Grotesque Alt 3 Thin.otf") format("truetype");
    font-style: normal;
    font-weight: 100; }

/*Lota Grotesque*/
@font-face {
    font-family: "Lota Grotesque";
    src: url("Los Andes - Lota Grotesque Black.otf") format("otf"),
    url("Los Andes - Lota Grotesque Black.otf") format("truetype");
    font-style: normal;
    font-weight: 700; }
@font-face {
    font-family: "Lota Grotesque";
    src: url("Los Andes - Lota Grotesque Black It.otf") format("otf"),
    url("Los Andes - Lota Grotesque Black It.otf") format("truetype");
    font-style: italic;
    font-weight: 700; }
@font-face {
    font-family: "Lota Grotesque";
    src: url("Los Andes - Lota Grotesque Bold.otf") format("otf"),
    url("Los Andes - Lota Grotesque Bold.otf") format("truetype");
    font-style: normal;
    font-weight: 600; }
@font-face {
    font-family: "Lota Grotesque";
    src: url("Los Andes - Lota Grotesque Bold It.otf") format("otf"),
    url("Los Andes - Lota Grotesque Bold It.otf") format("truetype");
    font-style: italic;
    font-weight: 600; }
@font-face {
    font-family: "Lota Grotesque";
    src: url("Los Andes - Lota Grotesque Semi Bold.otf") format("otf"),
    url("Los Andes - Lota Grotesque Semi Bold.otf") format("truetype");
    font-style: normal;
    font-weight: 500; }
@font-face {
    font-family: "Lota Grotesque";
    src: url("Los Andes - Lota Grotesque Semi Bold It.otf") format("otf"),
    url("Los Andes - Lota Grotesque Semi Bold It.otf") format("truetype");
    font-style: italic;
    font-weight: 500; }
@font-face {
    font-family: "Lota Grotesque";
    src: url("Los Andes - Lota Grotesque Regular.otf") format("otf"),
    url("Los Andes - Lota Grotesque Regular.otf") format("truetype");
    font-style: normal;
    font-weight: 400; }
@font-face {
    font-family: "Lota Grotesque";
    src: url("Los Andes - Lota Grotesque Regular It.otf") format("otf"),
    url("Los Andes - Lota Grotesque Regular It.otf") format("truetype");
    font-style: italic;
    font-weight: 400; }
@font-face {
    font-family: "Lota Grotesque";
    src: url("Los Andes - Lota Grotesque Light.otf") format("otf"),
    url("Los Andes - Lota Grotesque Light.otf") format("truetype");
    font-style: normal;
    font-weight: 300; }
@font-face {
    font-family: "Lota Grotesque";
    src: url("Los Andes - Lota Grotesque Light It.otf") format("otf"),
    url("Los Andes - Lota Grotesque Light It.otf") format("truetype");
    font-style: italic;
    font-weight: 300; }
@font-face {
    font-family: "Lota Grotesque";
    src: url("Los Andes - Lota Grotesque Extra Light.otf") format("otf"),
    url("Los Andes - Lota Grotesque Extra Light.otf") format("truetype");
    font-style: normal;
    font-weight: 200; }
@font-face {
    font-family: "Lota Grotesque";
    src: url("Los Andes - Lota Grotesque Extra Light It.otf") format("otf"),
    url("Los Andes - Lota Grotesque Extra Light It.otf") format("truetype");
    font-style: italic;
    font-weight: 200; }
@font-face {
    font-family: "Lota Grotesque";
    src: url("Los Andes - Lota Grotesque Thin.otf") format("otf"),
    url("Los Andes - Lota Grotesque Thin.otf") format("truetype");
    font-style: normal;
    font-weight: 100; }
@font-face {
    font-family: "Tahoma";
    src: url("Tahoma.ttf") format("ttf"),
    url("Tahoma.ttf") format("truetype");
    font-style: normal;
    font-weight: 300; }
